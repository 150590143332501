import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';

export interface IOpportunity {
    id: number;
    category: string;
    purchase_price: number;
    sale_price: number;
    profit: number;
    buy_link: string;
    amazon_link: string | null;
    product_code: string | null;
    has_us_shipping: boolean;
    rank: number | null;
    description: string | null;
    is_flammable: boolean;
    photos: string[];
}

const Opportunities: React.FC = () => {
    const { token } = useAuth();
    const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchOpportunities = async () => {
            try {
                const response = await fetch('/api/opportunities', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Ошибка при загрузке вилок');
                }
                const data = await response.json();
                // Ожидается структура { data: IOpportunity[], meta: { ... } }
                setOpportunities(data.data);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOpportunities();
    }, [token]);

    if (loading) {
        return (
            <LoadingContainer>
                Загрузка...
            </LoadingContainer>
        );
    }

    if (error) {
        return (
            <ErrorContainer>
                {error}
            </ErrorContainer>
        );
    }

    return (
        <Layout>
            <PageTitle>Вилки (Профитные товары)</PageTitle>
            {opportunities.length === 0 ? (
                <NoOpportunitiesMessage>В данный момент нет доступных вилок.</NoOpportunitiesMessage>
            ) : (
                <OpportunityList>
                    {opportunities.map(opp => (
                        <OpportunityItem key={opp.id}>
                            {opp.photos && opp.photos.length > 0 && (
                                <OpportunityImage src={opp.photos[0]} alt={opp.category} />
                            )}
                            <OpportunityDetails>
                                <OpportunityCategoryText>
                                    Категория: {opp.category}
                                </OpportunityCategoryText>
                                {opp.description && (
                                    <OpportunityDescription>
                                        {opp.description}
                                    </OpportunityDescription>
                                )}
                                <OpportunityPrices>
                                    <PriceLabel>Закупка:</PriceLabel>
                                    <PriceValue>{opp.purchase_price.toFixed(2)}</PriceValue>
                                    <PriceLabel>Продажа:</PriceLabel>
                                    <PriceValue>{opp.sale_price.toFixed(2)}</PriceValue>
                                    <PriceLabel>Профит:</PriceLabel>
                                    <PriceValue style={{ color: opp.profit >= 0 ? 'green' : 'red' }}>
                                        {opp.profit.toFixed(2)}
                                    </PriceValue>
                                </OpportunityPrices>
                                <OpportunityLinks>
                                    <LinkButton href={opp.buy_link} target="_blank" rel="noreferrer">
                                        Купить
                                    </LinkButton>
                                    {opp.amazon_link && (
                                        <LinkButton href={opp.amazon_link} target="_blank" rel="noreferrer">
                                            Amazon
                                        </LinkButton>
                                    )}
                                </OpportunityLinks>
                            </OpportunityDetails>
                        </OpportunityItem>
                    ))}
                </OpportunityList>
            )}
        </Layout>
    );
};

const PageTitle = styled.h1`
  color: #001B4F;
  font-size: 32px;
  margin-bottom: 20px;
`;

const OpportunityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const OpportunityItem = styled.div`
  display: flex;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
`;

const OpportunityImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
`;

const OpportunityDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OpportunityCategoryText = styled.h3`
  font-size: 20px;
  color: #001B4F;
  margin: 0;
`;

const OpportunityDescription = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0;
`;

const OpportunityPrices = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
`;

const PriceLabel = styled.span`
  font-weight: bold;
`;

const PriceValue = styled.span`
  color: #000;
`;

const OpportunityLinks = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const LinkButton = styled.a`
  background-color: #ff9900;
  color: #fff;
  padding: 10px 20px;
  border-radius: 24px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff8c00;
  }
`;

const LoadingContainer = styled.div`
  text-align: center;
  margin-top: 100px;
  font-size: 24px;
`;

const ErrorContainer = styled.div`
  text-align: center;
  margin-top: 100px;
  font-size: 24px;
  color: red;
`;

const NoOpportunitiesMessage = styled.p`
  font-size: 18px;
  color: #333;
`;

export default Opportunities;
